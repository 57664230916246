<template>
  <b-container>
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <h2 class="mb-1">Consents</h2>
    <div v-if="busy">
      <Busy primary :size="3" text="Loading..." />
    </div>
    <div v-else>
      <!-- Consent List Information -->
      <p>
        Connect Date: {{ consentList.deadline }} <br />Consent Type:
        <span v-if="consentList.consentType === 'optIn'" class="ml-1 badge badge-pill badge-success"
          >Opt In</span
        >
        <span
          v-else-if="consentList.consentType === 'optOut'"
          class="ml-1 badge badge-pill badge-primary"
          >Opt Out</span
        >
      </p>

      <!-- Actions Button -->
      <b-dropdown text="Actions" lazy class="mb-4">
        <b-dropdown-item :to="{ name: 'consent-lists' }">
          <font-awesome-icon :icon="['fas', 'user-check']" class="mr-1 text-muted space-right" />All
          Consent Lists
        </b-dropdown-item>
        <div v-if="isAdmin">
          <b-dropdown-divider />
          <b-dropdown-item
            :to="{
              name: 'consent-list-data',
              params: { consentListId: consentListId, viewMode: false }
            }"
          >
            <font-awesome-icon :icon="['far', 'edit']" class="mr-1 text-muted space-right" />Edit
            Consent List
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'consent-list-data', params: { consentListId: consentListId } }"
          >
            <font-awesome-icon :icon="['far', 'eye']" class="mr-1 text-muted space-right" />View
            Consent List
          </b-dropdown-item>
        </div>
      </b-dropdown>
      <!-- Delete Button -->
      <b-button
        class="mb-4 ml-2"
        variant="danger"
        :disabled="checked.length === 0"
        @click="showModal = true"
      >
        <font-awesome-icon class="mr-1" :icon="['fas', 'trash']" />Delete Selected
      </b-button>

      <p v-if="consentList.consents === null">No consents have been submitted.</p>
      <b-table v-else :items="consentList.consents" :fields="consentFields">
        <template v-slot:cell(selected)="row">
          <b-form-checkbox @change="checkedHandler(row.item)"></b-form-checkbox>
        </template>
        <template v-slot:cell(createdTime)="row">{{
          formatDateTime(row.item.createdTime)
        }}</template>
      </b-table>
    </div>
    <b-modal v-model="showModal" title="Delete Consents" @ok="deleteConsents">
      <p>Are you sure you would like to delete selected consents?</p>
    </b-modal>
  </b-container>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import { EventBus } from "@/components/eventbus";
import Console from "@/console";
import Busy from "@/components/Busy";
import CognitoAuth from "@/cognito/cognitoauth";

export default {
  data() {
    return {
      title: "Consents",
      isAdmin: null,
      busy: true,
      consentListId: this.$route.params.consentListId,
      consentList: {},
      consentFields: [
        { key: "selected", sortable: false, label: "" },
        { key: "businessName", sortable: true },
        { key: "email", sortable: true },
        { key: "name", sortable: true },
        { key: "phoneNumber", sortable: true },
        { key: "createdTime", sortable: true }
      ],
      checked: [],
      showModal: false
    };
  },
  created() {
    this.fetchData();
  },
  components: { Busy },
  methods: {
    formatDateTime: FormatHelper.formatDateTimeToMin,
    async fetchData() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();

      let client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}consentlists/` + this.consentListId)
        .then(response => {
          Console.log(response);
          this.consentList = response.data.consentList;
          this.consentList.deadline = FormatHelper.formatDate(this.consentList.deadline);
          // If consent list is opt-out, display the opt out-reason
          if (this.consentList.consentType === "optOut") {
            this.consentFields.push({ key: "reason", sortable: false });
          }
          this.busy = false;
        })
        .catch(e => {
          this.busy = false;
          this.showWarning("There was a problem fetching the consent data.");
          Console.error(e);
        });
    },
    checkedHandler(consent) {
      if (this.checked.includes(consent)) {
        this.checked = this.checked.filter(item => item !== consent);
      } else {
        this.checked.push(consent);
      }
    },
    async deleteConsents() {
      this.busy = true;

      let client = await ApiHelper.http();
      await client
        .delete(`${ApiHelper.endPoint()}consentlists/` + this.consentListId + "/consent", {
          data: { consents: this.checked }
        })
        .then(() => {
          this.fetchData();
        })
        .catch(e => {
          this.busy = false;
          this.showWarning("There was a problem deleting consents.");
          Console.error(e);
        });
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    }
  }
};
</script>
